export const PAGE_TYPE = Object.freeze({
  HOME_PAGE: "home-page",
  SECTION_PAGE: "section-page",
  TAG_PAGE: "tag-page",
  SEARCH_PAGE: "search-page",
  STORY_PAGE: "story-page",
  CATALOG_PAGE: "catalog-page",
  STORY_PUBLIC_PREVIEW_PAGE: "story-public-preview-page",
  STORY_PREVIEW: "story-preview",
  HOME_PREVIEW: "home-preview",
  STATIC_PAGE: "static-page",
  UGC_PAGE: "ugc-page",
  AUTHOR_PAGE: "author-page"
});

export const TEMPLATES = Object.freeze({
  THUMBNAIL_CAROUSEL: "ThumbnailCarousel",
  THREE_COLUMN_STORIES_WITH_SUBSCRIBE: "ThreeColumnStoriesWithSubscribe",
  TWO_COLUMN_FOUR_STORIES: "TwoColumnFourStories",
  FOUR_COLUMN_EIGHT_STORIES: "FourColumnEightStories",
  FOUR_STORIES_SECTION_TABS: "FourStoriesSectionTabs",
  TWO_COLUMN_SIX_STORIES_ONEAD: "TwoColumnSixStoriesOnead",
  STORIES_GRID_TAB: "StoriesGridTab"
});

export const TEMPLATES_CONFIG = {
  "collection-layouts": [
    {
      name: "ThumbnailCarousel",
      display: "Five Story ThumbnailCarousel on full width",
      "item-limit": "5",
      options: []
    },
    {
      name: "EightStoryGrid",
      display: "EightStoryGrid: 4 story cards in two similar row in a grid.",
      "item-limit": "8",
      options: []
    },
    {
      name: "ThreeColumnStoriesWithSubscribe",
      display:
        "ThreeColumnStoriesWithSubscribe: A 5 story list from child collection on the left and another 5 stories on center & right.",
      "item-limit": "2",
      options: []
    },
    {
      name: "TwoColumnFourStories",
      display: "TwoColumnFourStory: One large story on left and three small story cards on right side.",
      "item-limit": "4",
      options: []
    },
    {
      name: "StoriesGridTab",
      display: "StoriesGridTab: Show 4 stories from one collection with tab to change the collections.",
      "item-limit": "7",
      options: []
    },
    {
      name: "TwoColumnSixStoriesOnead",
      display: "TwoColumnSixStoriesOnead",
      "item-limit": "7",
      options: []
    }
  ]
};
