import React from "react";
import "./footer.m.css";
import PT from "prop-types";
import { Link } from "@quintype/components";
import { connect } from "react-redux";
import { Facebook } from "../../atoms/icons/facebook";
import { Twitter } from "../../atoms/icons/twitter";
import { Linkedin } from "../../atoms/icons/linkedin";
import { Instagram } from "../../atoms/icons/instagram";
import { Youtube } from "../../atoms/icons/youtube";
import get from "lodash/get";

const FooterBase = ({ socialLinks = {}, footerLinks = [], copyRight = "" }) => (
  <div styleName="footer-wrapper">
    <div styleName="footer">
      <div styleName="follow-us-container">
        <p styleName="follow-us-text"> Follow Us </p>
        <div styleName="social-icons">
          {socialLinks &&
            Object.keys(socialLinks).map(link => {
              const socialComponents = {
                "facebook-url": <Facebook width="15" height="30" color="white" />,
                "twitter-url": <Twitter width="31" height="24" color="white" />,
                "instagram-url": <Instagram width="25" height="25" color="white" />
              };
              return (
                socialComponents[link] && (
                  <a key={link} styleName="icon" target="_blank" rel="noopener noreferrer" href={socialLinks[link]}>
                    {socialComponents[link]}
                  </a>
                )
              );
            })}

          {
            <a
              styleName="icon"
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.youtube.com/channel/UCVgoGUZLkE6dPuwIuo0AStA"
            >
              {<Youtube />}
            </a>
          }
          {
            <a styleName="icon" target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/showcase/sme10x">
              {<Linkedin width="25" height="25" color="white" />}
            </a>
          }
        </div>
      </div>
      <div styleName="footer-menu-links">
        <ul styleName="static-links">
          {footerLinks.map(link => (
            <li styleName="static-link-item" key={link.completeUrl}>
              <Link href={link.completeUrl}>{link.title}</Link>
            </li>
          ))}
        </ul>
        <div styleName="copyright-wrapper">
          <div styleName="copyright">{copyRight}</div>

          <div>
            {/* <div styleName="powered-by">
              Powered by
              <a styleName="quintype-link" href="http://www.quintype.com" target="_blank" rel="noopener noreferrer">
                {" "}
                Quintype
              </a>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  </div>
);

function mapStateToProps(state) {
  return {
    socialLinks: get(state, ["qt", "config", "social-links"], []),
    footerLinks: get(state, ["qt", "data", "navigationMenu", "footerLinks"], []),
    copyRight: get(state, ["qt", "config", "publisher-settings", "copyright"], "Copyright 2018 ©")
  };
}

export const Footer = connect(
  mapStateToProps,
  () => ({})
)(FooterBase);

FooterBase.propTypes = {
  footerLinks: PT.arrayOf(
    PT.shape({
      isExternalLink: PT.bool,
      completeUrl: PT.string,
      title: PT.string
    })
  ),
  copyRight: PT.string,
  socialLinks: PT.object
};
