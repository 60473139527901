import React from "react";
import PropTypes from "prop-types";

export function Search({ color = "#d0021b", width = 24, height = 24 }) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>search</title>
      <desc>Created with Sketch.</desc>
      <g id="search" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Group-30" transform="translate(4.000000, 4.000000)" fill="#DC3A81">
          <path
            d="M11.0557122,10.2807504 C11.2826447,10.5039341 16,15.1433621 16,15.1433621 L15.1433621,16 L10.1912048,11.1296489 C9.11658341,11.9982956 7.74867147,12.5185127 6.25925634,12.5185127 C2.80236455,12.5185127 0,9.71614813 0,6.25925634 C0,2.80236451 2.80236455,0 6.25925634,0 C9.71614813,0 12.5185127,2.80236451 12.5185127,6.25925634 C12.5185127,7.79040675 11.9687329,9.19314922 11.0558102,10.2808468 Z M6.25925634,11.3070437 C9.04707234,11.3070437 11.3070437,9.04707234 11.3070437,6.25925634 C11.3070437,3.47144035 9.04707234,1.21146897 6.25925634,1.21146897 C3.47144034,1.21146897 1.21146897,3.47144035 1.21146897,6.25925634 C1.21146897,9.04707234 3.47144034,11.3070437 6.25925634,11.3070437 Z"
            id="search-blue"
          />
        </g>
      </g>
    </svg>
  );
}

Search.propTypes = {
  color: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string
};
