import React from "react";
import get from "lodash/get";
import { connect } from "react-redux";

import { OffcanvasMenu } from "./offcanvas-menu";
import { NavBar } from "./nav-bar";

import "./styles.m.css";

class NavigationBase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOffcanvasOpen: false
    };
  }

  componentDidUpdate(newProps) {
    newProps.loading && this.state.isOffcanvasOpen && this.closeSidemenu();
  }
  openSidemenu() {
    this.setState({
      isOffcanvasOpen: true
    });
  }
  closeSidemenu() {
    this.setState({
      isOffcanvasOpen: false
    });
  }
  render() {
    return (
      <div styleName="navigation-component">
        <OffcanvasMenu
          {...this.props}
          isOffcanvasOpen={this.state.isOffcanvasOpen}
          closeMenu={() => this.closeSidemenu()}
        />
        <NavBar
          {...this.props}
          {...this.state}
          openSidemenu={() => this.openSidemenu()}
          closeSidemenu={() => this.closeSidemenu()}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    title: "smeadvisor",
    menu: get(state, ["qt", "data", "navigationMenu"], []),
    socialLinks: get(state, ["qt", "config", "social-links"], []),
    config: state.qt.config || {},
    loading: state.pageLoading,
    showOffcanvasMenu: state.offcanvasMenu
  };
}

export const NavigationComponent = connect(
  mapStateToProps,
  () => ({})
)(NavigationBase);
