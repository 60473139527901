import { renderIsomorphicComponent, renderComponent } from "@quintype/framework/client/start";
import { pickComponent } from "../isomorphic/pick-component";
import { NavigationComponent } from "../isomorphic/components/navigation-component";
import { Footer } from "../isomorphic/components/layouts/footer";
import { LoadingIndicatorComponent } from "../isomorphic/components/atoms/loading-indicator";

// This is a separate file as everything from here on is hot reloaded when the app changes

export function renderApplication(store) {
  renderComponent(NavigationComponent, "navbar", store);
  renderIsomorphicComponent("container", store, pickComponent, { hydrate: !global.qtLoadedFromShell });
  renderComponent(Footer, "footer", store);
  renderComponent(LoadingIndicatorComponent, "loading-indicator", store);
}
