import React from "react";
import get from "lodash/get";
import PropTypes from "prop-types";
import { HamburgerMenu } from "../hamburger-icon";
import { NavbarSearch } from "../navbar-search";
import { SubmenuItem } from "../helper-components";
import assetify from "@quintype/framework/assetify";
import logo from "../../../../assets/images/sme-10x-logo.png";
//import newlogo from "../../../../assets/images/sme_uae_logo.png";
import { Link } from "@quintype/components";
import "./styles.m.css";

class NavBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      style: {},
      style2: {}
    };
  }

  getBorder(color, index) {
    this.setState({
      style: {
        [index]: {
          "border-bottom": `4px solid ${color}`
        }
      }
    });
  }

  getBorderColor(color, index) {
    this.setState({
      style2: {
        [index]: {
          "border-bottom": `2px solid ${color}`
        }
      }
    });
  }

  getDottedBorderColor(color, index) {
    this.setState({
      style2: {
        [index]: {
          "border-bottom": `1px dotted ${color}`
        }
      }
    });
  }

  render() {
    return (
      <div styleName="navbar">
        <Link href="/" styleName="logo">
          {" "}
          <img src={assetify(logo)} alt="logo" />{" "}
        </Link>
        <ul styleName="menu-group-header-links">
          {get(this.props, ["menu", "default"], []).map((item, index) => {
            const color = get(item, ["data", "color"], "#dc3a81");
            return item.children.length < 1 ? (
              <li>
                <div
                  key={`${item["id"]}${index}`}
                  onClick={this.props.closeMenu}
                  onMouseOver={() => this.getBorder(color, index)}
                  onMouseOut={() => this.getBorder("transparent", index)}
                  style={this.state.style[index]}
                >
                  <SubmenuItem item={item} />
                </div>{" "}
                <div styleName="divider">|</div>
              </li>
            ) : (
              <li styleName="dropdown-element">
                <div
                  key={`${item["id"]}${index}`}
                  onClick={this.props.closeMenu}
                  onMouseOver={() => this.getBorder(color, index)}
                  onMouseOut={() => this.getBorder("transparent", index)}
                  style={this.state.style[index]}
                >
                  <div styleName="dropdown">
                    <SubmenuItem item={item} />
                    <ul styleName="dropdown-content">
                      {item.children.map((child, index) => (
                        <li
                          styleName="submenu-items"
                          key={index}
                          onMouseOver={() => this.getBorderColor(color, index)}
                          onMouseOut={() => this.getDottedBorderColor("grey", index)}
                          style={this.state.style2[index]}
                        >
                          <Link href={`/${child["section-slug"]}`}>{child.title}</Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>{" "}
                <div styleName="divider">|</div>
              </li>
            );
          })}
        </ul>
        <div styleName="navbar__right">
          <NavbarSearch {...this.props} />
        </div>
        <HamburgerMenu
          openSidemenu={this.props.openSidemenu}
          closeSidemenu={this.props.closeSidemenu}
          isOffcanvasOpen={this.props.isOffcanvasOpen}
        />
      </div>
    );
  }
}

NavBar.propTypes = {
  socialLinks: PropTypes.object
};

export { NavBar };
