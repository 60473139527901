import React from "react";

export function Youtube() {
  return (
    <svg width="26" height="18" viewBox="0 0 26 18" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <g transform="translate(-564 -6366)" fill="#FFF">
          <g transform="translate(285 6347)">
            <g transform="translate(264)">
              <g>
                <path d="M40.078125,21.8125 C40.2968752,22.65625 40.453125,23.9374998 40.546875,25.65625 L40.59375,28 L40.546875,30.34375 C40.453125,32.0937498 40.2968752,33.390625 40.078125,34.234375 C39.9218752,34.7656248 39.6406252,35.2343748 39.234375,35.640625 C38.8281248,36.0468752 38.34375,36.3281252 37.78125,36.484375 C36.9687502,36.7031252 35.0625,36.859375 32.0625,36.953125 L27.796875,37 L23.53125,36.953125 C20.53125,36.859375 18.6249998,36.7031252 17.8125,36.484375 C17.25,36.3281252 16.7656252,36.0468752 16.359375,35.640625 C15.9531248,35.2343748 15.6718748,34.7656248 15.515625,34.234375 C15.2968748,33.390625 15.140625,32.0937498 15.046875,30.34375 L15,28 C15,27.3124998 15.0156248,26.5312502 15.046875,25.65625 C15.140625,23.9374998 15.2968748,22.65625 15.515625,21.8125 C15.6718748,21.25 15.9531248,20.7656252 16.359375,20.359375 C16.7656252,19.9531248 17.25,19.6718748 17.8125,19.515625 C18.6249998,19.2968748 20.53125,19.140625 23.53125,19.046875 L27.796875,19 L32.0625,19.046875 C35.0625,19.140625 36.9687502,19.2968748 37.78125,19.515625 C38.34375,19.6718748 38.8281248,19.9531248 39.234375,20.359375 C39.6406252,20.7656252 39.9218752,21.25 40.078125,21.8125 Z M25.171875,31.84375 L31.875,28 L25.171875,24.203125 L25.171875,31.84375 Z" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
