import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import { SubmenuItem, SideMenuItem } from "../helper-components";
import { CloseIcon } from "../../atoms/icons/close-icon";
import "./styles.m.css";

class OffcanvasMenu extends React.Component {
  constructor(props) {
    super(props);
    this.closeMenu = this.closeMenu.bind(this);
    this.getStyles = this.getStyles.bind(this);
  }
  closeMenu() {
    this.props.closeMenu();
  }
  getStyles() {
    return this.props.isOffcanvasOpen ? "sidebar sidebar_open" : "sidebar";
  }
  render() {
    return (
      <div>
        <nav styleName={this.getStyles()}>
          {/* Show the topbar in mobile */}
          <div styleName="top-bar">
            <div onClick={() => this.closeMenu()} styleName="btn_close-menu">
              <CloseIcon color="#fff" onClick={() => this.closeMenu()} />
            </div>
          </div>
          <ul styleName="menu-group-sections">
            {get(this.props, ["menu", "default"], []).map((item, index) => {
              if (item.children.length) {
                return <SideMenuItem item={item} key={`${item["id"]}${index}`} />;
              }
              return (
                <li styleName="menu-item menu-item_small" key={`${item["id"]}${index}`} onClick={this.closeMenu}>
                  <SubmenuItem item={item} />
                </li>
              );
            })}
          </ul>
        </nav>
      </div>
    );
  }
}

OffcanvasMenu.propTypes = {
  closeMenu: PropTypes.func,
  isOffcanvasOpen: PropTypes.bool
};
export { OffcanvasMenu };
