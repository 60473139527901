import React from "react";
import "./styles.m.css";
import PropTypes from "prop-types";

function HamburgerMenu({ openSidemenu, closeSidemenu, isOffcanvasOpen }) {
  return !isOffcanvasOpen ? (
    <div styleName="burger-menu" onClick={openSidemenu}>
      <HamburgerSvg />
    </div>
  ) : (
    <div styleName="burger-menu" onClick={closeSidemenu}>
      <CloseSvg />
    </div>
  );
}

const HamburgerSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z" fill="#333" />
  </svg>
);

const CloseSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
    <g fill="none" fillRule="nonzero">
      <path d="M-5-5h24v24H-5z" />
      <path fill="#FFF" d="M.636 11.95L11.95.636l1.414 1.414L2.05 13.364z" />
      <path fill="#FFF" d="M2.05.636L13.364 11.95l-1.414 1.414L.636 2.05z" />
    </g>
  </svg>
);

HamburgerMenu.propTypes = {
  openSidemenu: PropTypes.func,
  closeSidemenu: PropTypes.func,
  isOffcanvasOpen: PropTypes.bool
};

export { HamburgerMenu };
