import React from "react";
import { SearchBox } from "@quintype/components";
import { Search } from "../../atoms/icons/search";
import { SearchOpen } from "../../atoms/icons/search-open";
import { CloseIcon } from "../../atoms/icons/close-icon";
import "./styles.m.css";

function DrawForm({ children }) {
  return [
    <label styleName="qt-search__form-label" htmlFor="searchForm" key="1">
      {children}
    </label>,
    <button type="submit" styleName="qt-search__form-submit" key="2" name="search">
      <SearchOpen width="16" height="16" />
    </button>
  ];
}

class NavbarSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSearchFormOpen: false,
      initialized: false
    };
  }

  componentDidMount() {
    this.setState({
      initialized: true
    });
  }

  toggleSearchForm() {
    this.setState({ isSearchFormOpen: !this.state.isSearchFormOpen }, () => {
      this.state.isSearchFormOpen && document.getElementById("searchForm").focus();
    });
  }

  render() {
    let formStyle = this.state.isSearchFormOpen
      ? {
          transform: "translate(0, 0)"
        }
      : null;

    let initialStyle = {
      opacity: this.state.initialized ? 1 : 0
    };
    return (
      <div style={initialStyle}>
        <button styleName="btn_search" onClick={() => this.toggleSearchForm()} name="open-search">
          <Search width="22" height="22" />
        </button>
        <div id="searchBox" styleName="qt-search-form" style={formStyle}>
          {this.state.isSearchFormOpen && (
            <React.Fragment>
              <SearchBox
                className="component-wrapper"
                template={DrawForm}
                inputId="searchForm"
                inputRef={input => (this.input = input)}
                inputClassName="qt-search__form-input"
                formRef={searchForm => (this.searchForm = searchForm)}
                onSubmitHandler={() => this.setState({ isSearchFormOpen: false })}
                onEscape={() => this.setState({ isSearchFormOpen: false })}
                placeholder="Search Stories"
              />
              <div styleName="close-icon" onClick={() => this.toggleSearchForm()}>
                <CloseIcon color="#fff" />
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}

export { NavbarSearch };
