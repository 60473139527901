import React from "react";
import PropTypes from "prop-types";
import { Link } from "@quintype/components";
import "./helper-component-styles.m.css";

function SubmenuItem({ item }) {
  return item.isExternalLink ? (
    <a href={item.completeUrl} rel="noopener noreferrer" styleName="submenu-item">
      {item.title}
    </a>
  ) : (
    <Link href={item.completeUrl} styleName="submenu-item">
      {item.title}
    </Link>
  );
}

SubmenuItem.propTypes = {
  item: PropTypes.object
};

class SideMenuItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openSubMenu: false
    };
    this.toggleSubMenu = this.toggleSubMenu.bind(this);
  }
  toggleSubMenu() {
    this.setState(function(prevState, props) {
      return {
        openSubMenu: !prevState.openSubMenu
      };
    });
  }
  render() {
    return (
      <li styleName="menu-item" onClick={this.toggleSubMenu}>
        <div styleName="link-wrapper">
          <SubmenuItem item={this.props.item} key={this.props.item["id"]} />
          <SubmenuArrow isOpen={this.state.openSubMenu} />
        </div>
        <ul styleName={`submenu ${this.state.openSubMenu ? "submenu--show" : ""}`}>
          {this.props.item.children.map((child, index) => {
            return (
              <li key={child["id"]} styleName="menu-item menu-item--small ">
                <SubmenuItem item={child} />
              </li>
            );
          })}
        </ul>
      </li>
    );
  }
}

SideMenuItem.propTypes = {
  item: PropTypes.object
};

function SubmenuArrow({ isOpen }) {
  return (
    <div styleName={`submenu-arrow ${isOpen ? "submenu-arrow--open" : ""}`}>
      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="6" viewBox="0 0 14 6">
        <path fill="none" fillRule="nonzero" stroke="#979797" d="M1 6l6-6 6 6" />
      </svg>
    </div>
  );
}

SubmenuArrow.propTypes = {
  isOpen: PropTypes.bool
};

export { SubmenuItem, SideMenuItem };
